import { Image } from "@mantine/core";
import type { NextPage } from "next";
import Head from "next/head";
import Script from "next/script";
import { rollbar } from "../util/rollbar";
import Link from "next/link";
import { OutlinedButton } from "../components/outlined-button";
import StDavidsLogo from "../components/StDavidsLogo";

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Medicoach</title>
        <meta name="description" content="Medicoach" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="stylesheet" href="/site.css" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
          integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />

        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
          integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
      </Head>
      <Script
        src="https://code.jquery.com/jquery-3.4.1.js"
        strategy="beforeInteractive"
        onError={(e) => {
          console.error("jQuery script load error", e);
          rollbar.error("jQuery script load error", e);
        }}
      />
      <Script
        src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.2/jquery.min.js"
        strategy="beforeInteractive"
        onError={(e) => {
          console.error("ajax script load error", e);
          rollbar.error("ajax script load error", e);
        }}
      />

      <Script
        src="//cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"
        strategy="beforeInteractive"
        onError={(e) => {
          console.error("ajax21 script load error", e);
          rollbar.error("ajax21 script load error", e);
        }}
      />
      <Script
        src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
        strategy="beforeInteractive"
        onError={(e) => {
          console.error("jQuery33 script load error", e);
          rollbar.error("jQuery33 script load error", e);
        }}
      />

      <Script
        src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
        strategy="beforeInteractive"
        onError={(e) => {
          console.error("popper script load error", e);
          rollbar.error("popper script load error", e);
        }}
      />
      <div>
        <article>
          <header className="primary-header flex">
            <nav
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className="logo-box"
                style={{ paddingTop: "0px !important", display: "flex" }}
              >
                <a href="/">
                  <Image alt="" src="/medicoach-blue-logo.png" width="220" />
                </a>
                <a href="/">
                  <StDavidsLogo
                    imageProps={{ width: "220", sx: { height: "auto" } }}
                    alt=""
                  />
                </a>
              </div>
            </nav>
            <div className="nav-links">
              <Link href="/login">
                <OutlinedButton text="Login" />
              </Link>
            </div>

            <div className="nav-container" style={{ marginRight: "15px" }}>
              <div className="button_container" id="toggle">
                <span className="top"></span>
                <span className="middle"></span>
                <span className="bottom"></span>
              </div>

              <div className="overlay" id="overlay">
                <nav className="overlay-menu">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="#contact">Contact</a>
                    </li>
                    <li>
                      <a href="/login">Login</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <section className="hero-container">
            <div className="hero" id="hero-start">
              <div className="column_4_grid">
                <div className="text-wrapper">
                  <div className="hero-heading">Medicoach</div>

                  <div className="hero-text">
                    Cutting-edge software technology born from academic research
                    aims to understand the best-in-class. Name methodologies to
                    prevent, manage, and predict injury. The platform captures
                    the latest, world-leading clinical assessment screening
                    tools to identify players at risk of injury and provide
                    coaches with the tools to implement preventative
                    interventions to keep the squad healthy through the season.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="welcome-container">
            <div className="block">
              <Image
                alt=""
                src="/images/medicoach/preview01.jpg"
                data-speed="1"
                className="img-parallax"
              />
            </div>

            <div className="project-row">
              <div className="project-details">
                Research - Design - Medical equipment - Development - Financing
                - Prototyping
              </div>
            </div>
          </section>

          <section className="summary-container">
            <div className="column_4_grid">
              <div className="summary-content reveal">
                <h2>The benefits</h2>

                <ul>
                  <li>+ Clinically developed screening assessments;</li>
                  <li>+ Improved injury prevention processes</li>
                  <li>+ Best in className return to play protocols</li>
                  <li>
                    + Latest practice drills, fitness tests, and simulation
                    techniques
                  </li>
                  <li>
                    + Improved communication with local clinicians to improve
                    clinical services
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="work">
            <div className="column_4_grid">
              <div className="project-item a reveal">
                <Image alt="" src="/images/medicoach/dashboard.jpg" />
              </div>

              <div className="project-item b reveal">
                <Image alt="" src="/images/medicoach/practioner.jpg" />
              </div>

              <div className="project-item c reveal">
                <Image alt="" src="/images/medicoach/ui-ux-platform.jpg" />
              </div>

              <div className="project-item d reveal">
                <h2>Developed for coaches</h2>
                <p>
                  The platform is designed to provide coaches with the
                  best-in-class. Name tools to improve their knowledge,
                  practices, and career opportunities within their sport. We
                  understand the pressures coaches face, and Medicoach provides
                  digital resources, and support to bring out the best in their
                  players. Our drill bank of warm-ups, fitness tests, and skill
                  sessions gives the coach resources and tools to be a
                  world-leading coach.
                </p>
              </div>

              <div className="project-item e reveal">
                <Image alt="" src="/images/medicoach/player-history.jpg" />
              </div>

              <div className="project-item f reveal">
                <Image alt="" src="/images/medicoach/current-complaints.jpg" />
              </div>

              <div className="project-item g reveal">
                <Image alt="" src="/images/medicoach/team-assessment.jpg" />
              </div>

              <div className="project-item h reveal">
                <Image alt="" src="/images/medicoach/team-assessment.jpg" />
              </div>
            </div>

            {/* <div className="page-nav"> */}
            {/*   <a href="https://rorschach.co.za"> */}
            {/*     <button className="back">Go to RIS Website</button> */}
            {/*   </a> */}
            {/* </div> */}
          </section>
        </article>
        <footer className="footer" id="contact" style={{ marginTop: "50px" }}>
          <div className="column_4_grid">
            <div className="lets-talk side">
              <h6>Would you like to chat?</h6>
              <h4>Let's work together!</h4>
            </div>

            <div className="contact-button">
              <a href="mail:info@medicoach.co.za">
                <button className="contact">Get in touch</button>
              </a>
            </div>
          </div>
        </footer>
        <div className="copyright">
          <p>©Medicoach/All Rights Reseverved.</p>
        </div>
        <a className="gototop" href="#hero-start">
          <p>Back to top</p>
        </a>
      </div>
      <Script
        src="/site.js"
        strategy="afterInteractive"
        onError={(e) => {
          console.error("site js script load error", e);
          rollbar.error("site js script load error", e);
        }}
      />
    </>
  );
};

export default Home;
